<template>
  <div class="d-flex justify-center align-center h-100vh">
    <img src="@/assets/images/404.png" alt="" class="mr-72">
    <div class="d-flex flex-column justify-between">
      <h1 class="typography-1 color-1">Error!</h1>
      <span class="typography-8 mb-36">Sorry, we couldn't find anything <br> for your request.</span>
      <button
        id="404-back"
        class="opacity-btn mr-16"
        @click="$router.push('/')"
      >
        <Icon name="arrow-left-blue" class="mr-8" />
        Back
      </button>
    </div>
  </div>
</template>

<script>
import Icon from '../components/app/Icon.vue';

export default {
  name: '404',
  components: {
    Icon
  }
};
</script>

<style lang="scss" scoped>
  .opacity-btn {
    width: 260px;
  }

  .h-100vh {
    height: 100vh;
  }
</style>
